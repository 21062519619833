<template>
	<div class="calendar-card">
		<div class="calendar-bar">
			<!-- <button class="button button-year-minus" @click="minusYear(true)">
        &lt;
      </button> -->
			<button class="button button-month-minus" @click="minusMonth">
				上一月
			</button>
			<div class="calendar-date">
				{{ date.year }}-{{ date.month + 1 }}-{{ date.date }}
			</div>
			<button class="button button-month-plus" @click="plusMonth">下一月</button>
			<!-- <button class="button button-year-plus" @click="plusYear(true)">
        &gt;
      </button> -->
		</div>
		<div class="calendar-content">
			<ul class="ul-week">
				<li class="li-week" v-for="item in week">{{ item }}</li>
			</ul>
			<ul class="ul-day">
				<li class="li-day" v-for="(item, index) in days" :key="index" :isThisMonth="item.isThisMonth"
					@click="handleClick(item, index)">
					<div :class="item.hasEqual ? 'li-day-text li-day-text-box' : 'li-day-text'"
						:style="{ color: item.hasEqual ? '#fff' : '#000' }">
						{{ item.date }}
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	name: "SimpleCalendar",
	data() {
		return {
			date: {
				year: "",
				month: "",
				date: "",
			},
			current: {
				year: "",
				month: "",
				date: "",
			},
			week: ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"],
			days: [],
			monthINdex: 0,
			distinguish: false
		};
	},
	props: {
		infoDate: {
			type: Array,
			default: () => []
		},
	},
	watch: {
		infoDate: {
			handler(val) {
				if (val == undefined || this.distinguish) {
					return
				}
				// let time = new Date().getTime() - new Date(val[0]).getTime()
				// if(time < 0){
				let dateObj = new Date(val[0])

				let year = dateObj.getFullYear();
				let month = dateObj.getMonth();
				let day = dateObj.getDate();

				this.createCalendar(year, month)
				this.date.year = year
				this.date.month = month
				this.date.date = day
				// }
			},
			deep: true,
			immediate: true
		}
	},
	methods: {
		handleClick: function (item, index) {
			let month = item.month < 10 ? '0' + Number(item.month + 1) : Number(item.month + 1)
			let day = item.date < 10 ? '0' + item.date : item.date
			this.$emit('getDate', item.year + "-" + month + "-" + day)
			this.monthINdex = index
		},
		plusMonth: function () {
			if (this.date.month == 11) {
				this.date.month = 0;
				this.plusYear(false);
			} else {
				this.date.month++;
			}
			this.createCalendar(this.date.year, this.date.month);
			this.distinguish = true
			this.$emit('plusMonth', this.date.year + "-" + Number(this.date.month + 1).toString().padStart(2, '0'))
		},
		minusMonth: function () {
			if (this.date.month == 0) {
				this.date.month = 11;
				this.minusYear(false);
			} else {
				this.date.month--;
			}
			this.createCalendar(this.date.year, this.date.month);
			this.distinguish = true
			this.$emit('minusMonth', this.date.year + "-" + Number(this.date.month + 1).toString().padStart(2, '0'))
		},
		plusYear: function (create) {
			if (this.date.year == 2049) {
				this.date.year = 1970;
			} else {
				this.date.year++;
			}
			if (create) {
				this.createCalendar(this.date.year, this.date.month);
			}
		},
		minusYear: function (create) {
			if (this.date.year == 1970) {
				this.date.year = 2049;
			} else {
				this.date.year--;
			}
			if (create) {
				this.createCalendar(this.date.year, this.date.month);
			}
		},
		createCalendar: function (year, month) {
			let d = new Date();
			d.setFullYear(year);
			d.setMonth(month);
			d.setDate(1);
			let dayOfFirstDay = d.getDay();
			this.days = [];

			for (let i = 0; i < 31; i++) {
				d.setDate(1);
				d.setMonth(month);
				d.setDate(i - dayOfFirstDay + 1);

				let isThisMonth = 1;
				if (d.getMonth() == month) {
					isThisMonth = 2;
				} else {
					isThisMonth = 1;
				}
				if (
					this.current.date == d.getDate() &&
					this.current.month == d.getMonth() &&
					this.current.year == d.getFullYear()
				) {
					this.monthINdex = i;
					isThisMonth = 3;
					let date = {
						year: year,
						month: month,
						date: d.getDate(),
						isThisMonth: isThisMonth,
					};
					this.days.push(date);
				} else {
					let date = {
						year: d.getFullYear(),
						month: d.getMonth(),
						date: d.getDate(),
						isThisMonth: isThisMonth,
					};
					this.days.push(date);
				}
				setTimeout(() => {
					const formattedArr1 = this.infoDate.map(dateString => {
						const [year, month, date] = dateString.split('-');
						return {
							year: parseInt(year),
							month: parseInt(month - 1),
							date: parseInt(date)
						};
					});

					this.days.map(obj => {
						const {
							year,
							month,
							date
						} = obj;
						if (formattedArr1.some(item => item.year == year && item.month == month
								.toString().padStart(2, '0') && item.date == date.toString()
								.padStart(2, '0'))) {
							obj.hasEqual = true;
						}

						return obj;
					});
					this.$forceUpdate()
				}, 500)
			}
		},
	},
	created() {
		if(this.infoDate.length == 0){
			let d = new Date();
			this.date.year = d.getFullYear();
			this.date.month = d.getMonth();
			this.date.date = d.getDate();

			this.current.year = d.getFullYear();
			this.current.month = d.getMonth();
			this.current.date = d.getDate();
			this.createCalendar(this.current.year, this.current.month);
		}
	},
};
</script>

<style lang="scss" scoped>
body {
	background-color: #ddd;
}

.calendar-card {
	position: absolute;
	width: 501PX;
	height: 420PX;
	padding: 5PX 5PX 0PX 5PX;
	border-radius: 10PX;
	background-color: #fff;
	background: url('../assets/eventtheme/calendar.png');
	background-repeat: no-repeat;
	background-size: 100% 100%;
}

.calendar-bar {
	height: 40PX;
	color: #727272;
	line-height: 40PX;
	text-align: center;
	/* background-color: #ebeef5; */
}

.calendar-date {
	display: inline-block;
	width: 120PX;
	font-size: 20PX;
	font-weight: 500;
	color: #2F3F5A;
}

.button {
	font-size: 13PX;
	font-weight: 500;
	color: #0086F8;
	background: none;
	cursor: pointer;
	margin: 32PX;
}

.calendar-content {
	margin-top: 23PX;
}

.ul-week {
	width: 459PX;
	list-style: none;
	text-align: center;
	font-size: 13PX;
	color: #aaa;
	margin: 5PX 20PX 0 20PX;
	padding-inline-start: 0PX;
	padding-inline-end: 0PX;
	border-bottom: 1PX solid #eee;
	display: flex;
	justify-content: space-between;
}

.li-week {
	display: inline-block;
	width: 53PX;
	height: 20PX;
	background: #0086F8;
	border-radius: 10PX;
	margin-right: 11PX;
	font-size: 13PX;
	font-weight: 500;
	color: #FFFFFF;
}

.ul-day {
	/* display: grid;
  width: 459PX;
  grid-template-rows: repeat(6, 22PX);
  grid-template-columns: repeat(7, 35PX);
  list-style: none;
  text-align: center;
  margin: 5PX 20PX 5PX 20PX;
  padding-inline-start: 0PX;
  padding-inline-end: 0PX; */

	width: 459PX;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin-left: 20PX;
	align-items: center;
}

.li-day {
	/* display: inline-block;
  font-size: 10PX;
  line-height: 22PX;
  text-align: center;
  border-radius: 5PX;
  margin: 1PX;
  color: rgb(91, 91, 91); */
	font-size: 20PX !important;
	width: 64PX;
	height: 64PX;
	display: flex;
	justify-content: center;
	cursor: pointer;
	align-items: center;
}

.li-day:nth-child(7n) {
	margin-right: 0;
}

.li-day:hover {
	color: #fff;
	/* font-weight: 600; */
}

.li-day:hover .li-day-text {
	width: 35PX;
	height: 35PX;
	background: linear-gradient(0deg, #C90000 0%, #FF2828 100%);
	box-shadow: 0PX 4PX 10PX 0PX rgba(255, 147, 147, 0.5);
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff !important;
}

.li-day[isThisMonth="1"] {
	/* color: rgb(190, 190, 190); */
	/* font-size: 15PX; */
}

.li-day[isThisMonth="1"]:hover {
	/* background-color: #717de2;
  color: #fff;
  font-size: 15PX;
  cursor: pointer; */
}

.li-day-text-box {
	width: 35PX;
	height: 35PX;
	background: linear-gradient(0deg, #C90000 0%, #FF2828 100%);
	box-shadow: 0PX 4PX 10PX 0PX rgba(255, 147, 147, 0.5);
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
<template>
	<div style="background: #EBF5FD">
		<div class="back">
			<img :src="topImg" alt="">
		</div>
		<div class="navigation">
			<ul class="ul">
				<li @click="goDingyue(1)">专题首页</li>
				<li v-for="(item,index) in navList" :key="index" @click="goDingyue(index+2,item.name,item)">
					{{item.name}}
				</li>
			</ul>
		</div>

		<div v-if="display">
			<div class="lun" v-show="cards && cards.length > 0">
				<carousel :imgList="cards" :type="2" :width="(width - 24)" height="200PX" borderradius="16PX" @getclick="jump" background="rgb(0, 0, 0,0.38)"></carousel>
			</div>

			<div class="group_5" v-if="allData && allData[0] && allData[0].length > 0">
				<div class="text-wrapper_53 flex-row justify-between">
					<span v-for="(item,index) in allData[0]" :key="index" v-if="index<2"
						:class="index == 0 ? 'text_2':'text_3'" @click="getnav(index + 1,item)"
						:style="{'color':navindex == index + 1 ? '#1A99FD':'#000',}">{{item.name}}</span>
				</div>
				<div class="flex-col">
					<div class="section_3" :style="{visibility:navindex == 1 ? '':'hidden'}"></div>
					<div class="section_3" :style="{visibility:navindex == 2 ? '':'hidden'}"></div>
				</div>
				<div class="block_1"></div>
				<div class="text" v-for="(item,index) in articles" v-if="index<3" :key="index" @click="jump(item.id)">
					<div class="text_4">{{item.title}}</div>
					<div class="text_5">{{item.publishTime | format}}</div>
				</div>
			</div>

			<div class="statistics">
				<div class="title">
					<p class="name">获奖统计</p>
					<p class="date">统计截止：{{ statisticalTime }}</p>
				</div>
				<div class="table2">
					<el-table :data="tableData" border style="width: 100%;"
						:header-cell-style="{ background: 'rgba(0, 173, 249, 0.38)' }"
						:row-class-name="tableRowClassName">
						<el-table-column prop="areaName" label="单位" align="center">

						</el-table-column>
						<el-table-column prop="name" label="第一名" align="center">
							<template slot="header" slot-scope="scope">
								<div class="custom-header">
									<img src="../../../assets/eventtheme/one.png" alt="">
									{{ scope.column.label }}
								</div>
							</template>
							<template slot-scope="scope">
								{{ scope.row.first}}
							</template>
						</el-table-column>
						<el-table-column prop="name" label="第二名" align="center">
							<template slot="header" slot-scope="scope">
								<div class="custom-header">
									<img src="../../../assets/eventtheme/two.png" alt="">
									{{ scope.column.label }}
								</div>
							</template>
							<template slot-scope="scope">
								{{ scope.row.second}}
							</template>
						</el-table-column>
						<el-table-column prop="name" label="第三名" align="center">
							<template slot="header" slot-scope="scope">
								<div class="custom-header">
									<img src="../../../assets/eventtheme/three.png" alt="">
									{{ scope.column.label }}
								</div>
							</template>
							<template slot-scope="scope">
								{{ scope.row.third}}
							</template>
						</el-table-column>
						<el-table-column prop="fourth" label="第四名" align="center">
						</el-table-column>
						<el-table-column prop="fifth" label="第五名" align="center">
						</el-table-column>
						<el-table-column prop="sixth" label="第六名" align="center">
						</el-table-column>
						<el-table-column prop="seventh" label="第七名" align="center">
						</el-table-column>
						<el-table-column prop="eighth" label="第八名" align="center">
						</el-table-column>
						<el-table-column prop="total" label="合计" align="center">
						</el-table-column>
					</el-table>
				</div>
				<img src="../image/ztback.png" alt="" style="height: 400PX;">
			</div>

			<div class="statistics" style="margin-top: 8PX;">
				<div class="title">
					<p class="name">竞赛日历</p>
				</div>
				<div class="calendar-box">
					<div class="calendar">
						<div class="flrex">
							<div class="information" v-for="(item,index) in mchList" :key="index">
								<div class="rubric">
									<div class="rubricbox">
										{{item.name}}
									</div>
								</div>
								<div class="date">
									<img src="../image/sj.png" alt="">
									<div class="roundtext">时间：
										<div v-if="item.isJSON">
											<div v-for="(item,index) in item.start_time">
											  {{getTime(item)}}
											</div>
										</div>
										<div v-if="!item.isJSON">
											{{item.start_time}} - {{ item.end_time}}
										</div>
									</div>
								</div>
								<div class="location">
									<img src="../image/dd.png" alt="">
									<div class="roundtext">地点：{{item.address}}</div>
								</div>
							</div>
						</div>
						<div class="assembly">
							<Calendar @getDate="getDate" :infoDate="infoDate" @plusMonth="plusMonth" @minusMonth="minusMonth"></Calendar>
						</div>
					</div>
				</div>
				<img src="../image/ztback.png" alt="" style="height: 400PX;">
			</div>

			<!-- <div class="statistics" style="margin-top: 8PX;">
				<div class="title">
					<p class="name">赛事安排</p>
				</div>
				<div class="table">
					<el-table :data="scheduleList" border style="width: 100%;"
						:header-cell-style="{ background: 'rgba(0, 173, 249, 0.38)' }"
						:row-class-name="tableRowClassName">
						<el-table-column prop="name" label="赛事名称" align="center">
						</el-table-column>
						<el-table-column prop="address" label="地点" align="center">
						</el-table-column>
						<el-table-column prop="createTime" label="时间" align="center">
							<template slot-scope="scope">
								{{scope.row.start_time | format}}
							</template>
						</el-table-column>
						<el-table-column prop="content" label="赛事介绍" align="center">
							<template slot-scope="scope">
								<div v-html="scope.row.content"></div>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<img src="../image/ztback.png" alt="" style="height: 400PX;">
			</div> -->

			<div class="statistics" v-if="allData1 && allData1.length > 0" v-for="(item,index) in allData1"
				:key="index">
				<div v-for="(res,index1) in item" :key="index1" v-if="item && item.length > 0">
					<div class="title" style="margin-top: -5PX;">
						<p class="name">{{res.name}}</p>
						<p class="date border" @click="goDingyue(2,res.name,res)">查看所有</p>
					</div>
					<div class="wonderful-box" v-if="res.name == '精彩瞬间' || res.name == '精彩图集'">
						<!-- <div class="wonderful" v-for="(ess,index2) in res.articles" v-if="index2<3" :key="index2"
							@click="jump(ess.id)">
							<p class="name">{{ess.title}}</p>
							<img class="img"
								:src="ess.imgUrl" alt="">
						</div> -->
						
						<div class="swiper">
							<img src="../../../assets/eventtheme/theleftside.png" class="theleftside" alt="">
							<swiper :options="swiperOption" ref="mySwiper">
								<swiper-slide v-for="(item,index) in res.articles" :key="index">
									<div class="swiperinformation" @click="jump(item.id)">
										<div class="title">{{item.title}}</div>
										<img class="img"
											:src="item.imgUrl"
											alt="">
									</div>
								</swiper-slide>
							</swiper>
							<img src="../../../assets/eventtheme/therightside.png" class="therightside" alt="">
						</div>
					</div>
					<div class="project-case" v-else-if="res.name == '竞赛项目'" style="margin-top: 10PX;">
						<div class="project-box" :style="{'width':innerWidth}" v-for="(ess,index3) in res.articles" v-if="index3<6" :key="index3"
							@click="jump(ess.id)">
							<div class="project">
								<img :src="ess.imgUrl"
									alt="">
								<div class="title-box">
									<p class="name">{{ess.title}}</p>
								</div>
							</div>
						</div>
					</div>
					<div class="specific" v-else style="margin-top: 16PX;">
						<div class="text" v-for="(ess,index) in res.articles" v-if="index<5" :key="index"
							@click="jump(ess.id)">
							<div class="text_4">{{ess.title}}</div>
							<div class="text_5">{{ess.publishTime | format}}</div>
						</div>
					</div>
					<img src="../image/ztback2.png" alt="" v-if="res.name == '精彩瞬间' || res.name == '精彩图集'"
						style="height: 200PX;">
					<img src="../image/ztback.png" alt="" style="height: 400PX;" v-else>
				</div>
			</div>
			<div style="height: 20PX;"></div>
		</div>
		<div v-else>
			<div class="statistics">
				<div class="title">
					<p class="name">{{concentratetitle}}</p>
				</div>
				<div class="specific" style="margin-top: 20PX;">
					<div class="text" v-for="(ess,index) in concentrateList" :key="index" @click="jump(ess.id)">
						<div class="text_4">{{ess.title}}</div>
						<div class="text_5">{{ess.publishTime | format}}</div>
					</div>
				</div>
				<div class="paging" v-if="concentrateList.length > 0">
					<pagination :total="total" :type="2" @currentchange="currentchange" :go="4" :pageSize="4">
					</pagination>
				</div>
				<img src="../image/ztback.png" alt="" style="height: 400PX;">

			</div>
			<div style="height: 20PX;"></div>
		</div>

	</div>
</template>

<script>
	import Vue from 'vue'
	import Meta from 'vue-meta'
	Vue.use(Meta)
	import carousel from '@/components/carousel.vue'
	import Calendar from '@/components/calendar2.vue'
	import {
		Swiper,
		SwiperSlide
	} from 'vue-awesome-swiper'
	export default {
		name: '',
		components: {
			carousel,
			Calendar,
			Swiper,
			SwiperSlide,
		},
		data() {
			return {
				topImg: '',
				navList: [],
				cards: [],
				allData: [],
				articles: [],
				navindex: 1,
				statisticalTime: '',
				tableData: [],
				scheduleList: [],
				mchList: [],
				infoDate: [],
				allData1: [],
				concentratetitle: '',
				display: true,
				concentrateList: [],
				info: {
					id: '',
					pageNum: 1,
					pageSize: 4,
				},
				total: 0,
				swiperOption: {
					slidesPerView: 3, // 一屏显示的slide个数
					centeredSlides: true, // 居中的slide是否标记为active，默认是最左active,这样样式即可生效
					slideToClickedSlide: true, // 点击的slide会居中
					loop: true, // 循环播放, 可有无限滚动效果，初始加载即是滚动后的效果
					autoplay: {
						delay: 2000, //秒
						stopOnLastSlide: false,
						disableOnInteraction: false, //滑动不会失效
					},
					on: {
						// 该方法中的this都指代swiper本身
						tap: function() {
							console.log('点击的位置', this.activeIndex);
						}
					},
					navigation: {
						//下一张
						nextEl: '.therightside',
						//上一张
						prevEl: '.theleftside'
					},
				},
			}
		},
		computed: {
			width() {
				return Number(document.documentElement.clientWidth)
			},
			innerWidth(){
				return Number(window.screen.width) - 44 + 'PX'
			}
		},
		filters: {
			format(x) {
				if(x != null && x != ''){
					return x.split(" ")[0]
				}
			}
		},
		mounted() {
			document.body.style.backgroundColor = '#EBF5FD';
		},

		created() {
			
			document.title = this.$route.query.title + '-北京市体育竞赛管理和国际交流中心'
			this.$meta().refresh()
			
			this.$getwxsdk(this.$route.query.title + '-北京市体育竞赛管理和国际交流中心')
			
			this.getdata()
			this.$store.commit("EXIT_STATIONID", this.$route.query.id)
			this.getIndexImgList()
			this.getTopBanner()
			this.getNav()
			this.getIndexColumn()
			this.medalRankingList()
			// this.arrange()
		},
		activated() {
			this.$store.commit("EXIT_STATIONID", this.$route.query.id)
		},
		beforeRouteLeave(to, from, next) {
			document.body.style.backgroundColor = '#fff'
			this.$store.commit("EXIT_STATIONID", '1005012')
			next();
		},
		methods: {
			getTopBanner() {
				this.$api.getTopBanner({
					type: '1'
				}).then(res => {
					this.topImg = res.data.data[0].imgUrl
				})
			},
			getNav() {
				this.$api.getNav().then(res => {
					this.navList = res.data.data
				})
			},
			getIndexImgList() {
				this.$api.getIndexImgList().then((res) => {
					this.cards = res.data.data
				});
			},
			getIndexColumn() {
				this.$api.getIndexColumn(this.$route.query.id).then(res => {
					this.allData = res.data.data
					this.allData1 = res.data.data.slice(1)
					this.articles = res.data.data[0][0].articles
					this.articles.forEach(item=>{
						item.title= item.title.replace(/<br>/g, ' ')
					})
					this.allData1.forEach(item=>{
						item.forEach(rss=>{
							rss.articles.forEach(css=>{
								css.title= css.title.replace(/<br>/g, ' ')
							})
						})
					})
				})
			},
			getnav(index, item) {
				this.navindex = index
				this.articles = item.articles
			},
			jump(id) {
				this.$router.push({
					path: '/h5/H5articledetails',
					query: {
						id: id,
						refresh: new Date().getTime(),
					}
				})
			},
			medalRankingList() {
				this.$api.medalRankingList(this.$route.query.id,1).then(res => {
					this.tableData = res.data.data.rankingList
					this.statisticalTime = res.data.data.statisticalTime
				})
			},
			// arrange() {
			// 	let data = {
			// 		stationId: this.$route.query.id,
			// 	}
			// 	this.$api.matchInfoListAll(data).then(res => {
			// 		this.scheduleList = res.data.data
			// 	})
			// },
			tableRowClassName({
				row,
				rowIndex
			}) {
				if (String(rowIndex / 2).indexOf(".") > -1) {
					return 'success-row';
				}
				return '';
			},
			getdata() {
				const timeOne = new Date()
				const year = timeOne.getFullYear()
				let month = timeOne.getMonth() + 1
				let day = timeOne.getDate()
				month = month < 10 ? '0' + month : month
				day = day < 10 ? '0' + day : day
				const NOW_MONTHS_AGO = `${year}-${month}`
				let data = {
					stationId:this.$route.query.id
				}
				this.$api.matchInfoDateOther(data).then(res=>{
					this.matchInfoDate(res.data.data[0])
				})
			},
			matchInfoDate(e) {
				let data = {
					startTime: e,
					stationId: this.$route.query.id
				}
				this.$api.matchInfoDate(data).then(res => {
					this.infoDate = res.data.data
					this.getDate(this.infoDate[0] || e)
				})
			},
			getDate(e) {
				let data = {
					stationId: this.$route.query.id,
					startTime: e
				}
				this.$api.matchInfoList(data).then(res => {
					res.data.data.forEach(item => {
					  item.isJSON = this.isJSON(item.start_time)
					  if (this.isJSON(item.start_time)) {
					    item.start_time = JSON.parse(item.start_time)
					  }
					})
					this.mchList = res.data.data
				})
			},
			isJSON(str) { // 判断是否json
			  if (typeof str != 'string') { // 1、传入值必须是 字符串
			    return false;
			  }
			  try {
			    var obj = JSON.parse(str); // 2、仅仅通过 JSON.parse(str)，不能完全检验一个字符串是JSON格式的字符串
			    if (typeof obj == 'object' && obj) { //3、还必须是 object 类型
			      return true;
			    } else {
			      return false;
			    }
			  } catch (e) {
			    return false;
			  }
			  return false;
			},
			getTime(dates) { // 时间转换
			  let startDate = new Date(dates[0]);
			  let endDate = new Date(dates[1]);
			  let formattedStartDate =
			    `${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`;
			  let formattedEndDate =
			    `${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${endDate.getDate().toString().padStart(2, '0')}`;
			  if(formattedStartDate == formattedEndDate){
			  	return `${formattedStartDate}`;
			  }else{
			  	return `${formattedStartDate} 至 ${formattedEndDate}`;
			  }
			},
			plusMonth(e) {
				this.matchInfoDate(e)
			},
			minusMonth(e) {
				this.matchInfoDate(e)
			},
			goDingyue(e, title, row) {
				this.concentratetitle = title
				if (e == 1) {
					this.display = true
					this.concentrateList = []
					this.getIndexImgList()
				} else {
					this.display = false
					this.info.id = row.id
					this.getArticleListById()
				}
			},
			currentchange(val) {
				this.info.pageNum = val
				this.getArticleListById()
			},
			getArticleListById() {
				this.$api.getArticleListById(this.info).then(res => {
					this.concentrateList = res.data.data.list
					this.total = res.data.data.total
				})
			},
		}
	}
</script>
<style scoped lang="scss">
	.back {
		width: 100%;
		min-height: 197PX;
	}

	img {
		width: 100%;
		height: 100%;
	}

	.navigation {
		overflow-x: auto;
		background: #FFFFFF;
		display: flex;
		padding-bottom: 10PX;

		ul {
			display: flex;
		}

		li {
			font-size: 14PX;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #fff;
			flex-shrink: 0;
			margin-left: 20PX;
			padding: 5PX 20PX;
			background: #1A99FD;
			border-radius: 50PX;
		}

	}

	.navigation::-webkit-scrollbar {
		display: none;
	}

	.lun {
		margin: 14PX 12PX;

		::v-deep .instBox {
			height: 40PX;
		}

		::v-deep .instBox_back {
			height: 40PX;
		}

		::v-deep .instBox_back .text {
			font-size: 15PX;
			margin-top: 10PX !important;
		}

		::v-deep .instBox_text1 {
			margin-top: -51PX !important;
		}
	}

	.group_5 {
		box-shadow: 0PX 0PX 9PX 0PX rgba(7, 151, 200, 0.18);
		background-color: rgba(255, 255, 255, 1);
		border-radius: 10PX;
		margin: 11PX 14PX;
		padding-bottom: 21PX;
	}

	.text-wrapper_53 {
		padding-top: 10PX;
		display: flex;
	}

	.text_2 {
		width: 50%;
		display: block;
		overflow-wrap: break-word;
		color: rgba(0, 0, 0, 1);
		font-size: 15PX;
		font-family: SourceHanSansCN-Medium;
		font-weight: 500;
		text-align: left;
		white-space: nowrap;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.xian {
		height: 20PX;
		width: 1PX;
		background: rgba(146, 146, 146, 0.48);
	}

	.text_3 {
		width: 50%;
		display: block;
		overflow-wrap: break-word;
		color: rgba(0, 0, 0, 1);
		font-size: 15PX;
		font-family: SourceHanSansCN-Medium;
		font-weight: 500;
		text-align: left;
		white-space: nowrap;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.block_1 {
		border-radius: 18PX;
		height: 1PX;
		border-bottom: 1PX solid rgba(230, 230, 230, 1);
	}

	.flex-col {
		display: flex;
		margin-top: 13PX;
		justify-content: center;
	}

	.section_3 {
		background-color: rgba(26, 153, 253, 1);
		width: 20%;
		height: 6PX;
		margin-right: 113PX;
	}

	.section_3:nth-child(2) {
		margin-right: 0;
	}

	.text {
		margin-top: 21PX;
	}

	.text_4 {
		display: block;
		color: rgba(0, 0, 0, 1);
		font-size: 13PX;
		font-family: SourceHanSansCN-Medium;
		font-weight: 500;
		margin: 0 21PX;
		width: 90%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
	}

	.text_5 {
		color: rgba(0, 0, 0, 0.5);
		font-size: 12PX;
		font-family: SourceHanSansCN-Regular;
		font-weight: 400;
		margin: 13PX 21PX 0;

	}

	.statistics {
		// height: 259PX;
		margin: 11PX 11PX;
	}

	.statistics img {
		width: 100%;
	}

	.statistics .title {
		display: flex;
		position: absolute;
		justify-content: space-between;
		align-items: center;
		width: 96%;
	}

	.statistics .title .name {
		font-size: 13PX;
		font-weight: bold;
		color: #0056F0;
		margin-top: 4%;
		margin-left: 12%;
	}

	.statistics .title .date {
		font-size: 11PX;
		font-weight: 500;
		color: #0077EC;
		margin-top: 18PX;
		margin-right: 8%;
	}

	.statistics .title .border {
		border: 1PX solid #0084F8;
		border-radius: 15PX;
		padding: 2PX 15PX;
		cursor: pointer;
		z-index: 999999;
	}

	.table {
		width: 89% !important;
		position: absolute;
		padding-right: 1%;
		height: 330PX;
		overflow-y: auto;
		color: #000;
		margin-top: 50PX;
		margin-left: 3%;
	}

	.table ::v-deep .el-table {
		border-radius: 10PX;
		font-size: 11PX;
	}

	.table ::v-deep .el-table .success-row {
		background: rgba(0, 119, 236, 0.12);
	}

	/*滚动条样式*/
	.table::-webkit-scrollbar {
		width: 8PX;
		position: absolute;
	}

	.table::-webkit-scrollbar-thumb {
		border-radius: 10PX;
		background: rgba(0, 132, 248, 0.32);
		box-shadow: 1PX 13PX 18PX 0PX rgba(81, 81, 81, 0.25)
	}

	.table::-webkit-scrollbar-track {
		border-radius: 10PX;
		background: rgba(166, 166, 166, 0.08);
	}

	.custom-header {
		display: flex;
		align-items: center;
	}

	.custom-header img {
		width: 15PX;
		height: 20PX;
		margin-right: 7PX;
	}
	
	.table2 {
		width: 89% !important;
		position: absolute;
		padding-right: 1%;
		height: 330PX;
		overflow-y: auto;
		color: #000;
		margin-top: 50PX;
		margin-left: 3%;
	}
	
	.table2 ::v-deep .el-table {
		border-radius: 10PX;
		font-size: 11PX;
	}
	
	.table2 ::v-deep .el-table .success-row {
		background: rgba(0, 119, 236, 0.12);
	}
	
	/*滚动条样式*/
	.table2::-webkit-scrollbar {
		width: 8PX;
		position: absolute;
	}
	
	.table2::-webkit-scrollbar-thumb {
		border-radius: 10PX;
		background: rgba(0, 132, 248, 0.32);
		box-shadow: 1PX 13PX 18PX 0PX rgba(81, 81, 81, 0.25)
	}
	
	.table2::-webkit-scrollbar-track {
		border-radius: 10PX;
		background: rgba(166, 166, 166, 0.08);
	}
	
	.table2 ::v-deep .el-table thead tr th:nth-child(2){
		background-image: linear-gradient(#F8B65C, #FFE71D, #FFBA5B) !important;
	}
	.table2 ::v-deep .el-table thead tr th:nth-child(3){
		background-image: linear-gradient(#EEEDEC, #D1CCCA, #F0EFEE) !important;
	}
	.table2 ::v-deep .el-table thead tr th:nth-child(4){
		background-image: linear-gradient(#FFE5BB, #DE9D76, #FFF7D9) !important;
	}

	.table2 ::v-deep .el-table thead tr {
		color: #000;
	}

	.table2 ::v-deep .el-table__body td {
		color: #000;
	}

	.assembly {
		transform: scale(0.6);
		display: flex;
    	justify-content: center;
	}

	.calendar-box {
		.calendar {
			position: absolute;
			width: 94%;

			.flrex {
				margin-top: 50PX;
				overflow-y: auto;
				height: 94PX;
				width: 98%;

				.information {
					margin-bottom: 13PX;
					display: flex;
					justify-content: center;
					flex-direction: column;
					align-items: center;
					padding: 0 10PX;

					.rubricbox {
						font-size: 13PX;
						font-family: Source Han Sans CN;
						font-weight: bold;
						color: #000000;
					}

					.date {
						display: flex;
						align-items: center;
						margin-top: 4PX;

						img {
							width: 20PX;
							height: 20PX;
							margin-right: 4PX;
						}

						font-size: 12PX;

						.roundtext {
							padding: 5PX 10PX;
							background: #F1FCFF;
							border-radius: 8PX;
							font-family: Source Han Sans CN;
							font-weight: 400;
							color: #0086F8;
							display: flex;
							align-items: center;
						}
					}

					.location {
						margin-top: 7PX;
						display: flex;
						align-items: center;

						img {
							width: 20PX;
							height: 20PX;
							margin-right: 4PX;
						}

						font-size: 12PX;

						.roundtext {
							padding: 5PX 10PX;
							background: #F1FCFF;
							border-radius: 8PX;
							font-family: Source Han Sans CN;
							font-weight: 400;
							color: #0086F8;
						}
					}
				}
			}

			.flrex::-webkit-scrollbar {
				width: 8PX;
				position: absolute;
			}

			.flrex::-webkit-scrollbar-thumb {
				border-radius: 8PX;
				background: #0086F8;
			}

			.flrex::-webkit-scrollbar-track {
				border-radius: 8PX;
				background: rgba(68, 68, 68, 0.14);

			}
		}
	}

	.project-case {
		position: absolute;
		display: flex;
		flex-wrap: wrap;
		margin-left: 11PX;
		padding-top: 50PX;
	}

	.project-box {
		width: 348PX;
		height: 43PX;
		margin-bottom: 11PX;
		margin-right: 21PX;
		border-radius: 10PX;
		background: linear-gradient(to bottom, #F0F7FC, #E2F0FA);
	}

	.project {
		display: flex;
	}

	.project img {
		width: 26PX;
		height: 26PX;
		margin-top: 8PX;
		margin-left: 10PX;
		border-radius: 5PX;
		flex-shrink: 0;
	}

	.project .title-box {
		width: 85%;
		margin-left: 15PX;
		margin-top: 12PX;
		padding-right: 25PX;
	}

	.project .name {
		font-size: 13PX;
		font-weight: 500;
		color: #000000;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
	}

	.project .brief {
		width: 75PX;
		height: 31PX;
		font-size: 11PX;
		font-weight: 400;
		color: #000000;
		// margin-top: 5PX;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.specific {
		width: calc(100% - 22PX);
		position: absolute;
		padding-top: 20PX;
	}

	.paging {
		display: flex;
		align-items: center;
		position: absolute;
		top: 85%;
		margin-left: 24PX;

		.assembly {
			transform: scale(1);
		}
	}

	.paging span {
		margin-right: 5PX;
	}

	.wonderful-box {
		position: absolute;
		display: flex;
		margin-top: 80PX;
		padding: 0 11PX;
		width: calc(100% - 22PX);

		.wonderful {
			width: 103PX;
			height: 70PX;
			margin-right: 8%;
			border-radius: 16PX;
		}
		.wonderful:nth-child(3){
			margin-right: 0;
		}

		.wonderful .img {
			width: 100%;
			height: 100%;
			border-radius: 16PX;
			border-radius: 16PX;
		}

		.wonderful .name {
			width: 103PX;
			background: rgba(0, 0, 0, 0.55);
			font-size: 12PX;
			font-weight: bold;
			color: #F8F9FD;
			border-bottom-left-radius: 16PX;
			border-bottom-right-radius: 16PX;
			position: absolute;
			padding: 5PX 10PX;
			bottom: 0;
			text-align: center;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			-o-text-overflow: ellipsis;
		}
	}
	.swiper {
		width: 100%;
		display: flex;
		align-items: center;
	}
	
	.swiper-container {
		width: 100%;
		height: 100%;
	}
	
	.theleftside {
		width: 16PX !important;
		height: 19PX !important;
		// margin-right: 14PX;
		cursor: pointer;
	}
	
	.therightside {
		width: 16PX !important;
		height: 19PX !important;
		margin-left: 14PX;
		cursor: pointer;
	}
	
	.swiper ::v-deep .swiper-wrapper {
		display: flex;
		align-items: center
	}
	
	.swiper-slide {
		text-align: center;
		font-size: 18PX;
	
		/* Center slide text vertically */
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		align-items: center;
		transition: 300ms;
		// margin-right: 5PX;
	}
	
	.swiper-slide-active,
	.swiper-slide-duplicate-active {
		transform: scale(1);
	}
	
	.swiperinformation {
		width: 79PX !important;
		height: 60PX !important;
		position: absolute;
	}
	
	.swiperinformation .title {
		width: 79PX !important;
	}
	
	::v-deep .swiper-slide-active {
		width: 116PX !important;
		margin: 0 10PX;
	}
	
	::v-deep .swiper-slide-active .swiperinformation {
		width: 116PX !important;
		height: 86PX !important;
		position: relative !important;
	
	}
	
	::v-deep .swiper-slide-active .swiperinformation .title {
		width: 116PX !important;
	}
	
	::v-deep .swiper-slide-active .swiperinformation .img {
		width: 100%;
		height: 100%;
	}
	
	.swiperinformation .title {
		position: absolute;
		width: 100%;
		height: 14PX;
		font-size: 9PX;
		font-weight: 500;
		color: #FFFFFF;
		bottom: 0;
		background: rgba(0, 0, 0, 0.4);
		border-bottom-left-radius: 16PX;
		border-bottom-right-radius: 16PX;
		line-height: 14PX;
		padding: 0 10PX;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
	}
	
	.swiperinformation .img {
		width: 100%;
		height: 100%;
		border-radius: 16PX;
		border-radius: 16PX;
	}
	
	::v-deep .swiper-slide-active .swiperinformation .title {
		height: 21PX !important;
		line-height: 21PX !important;
		font-size: 12PX !important;
	}
</style>
